import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import SuperAdminLayout from '../layouts/superadmin';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AdminLayout from '../layouts/admin';
import CustomerLayout from '../layouts/customer';
import ContractorLayout from '../layouts/contractor';
import TechnicianLayout from '../layouts/technician'
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// import { PATH_AFTER_LOGIN } from '../config';
// eslint-disable-next-line import/named
import { PATH_AFTER_ADMIN_LOGIN, PATH_AFTER_CUSTOMER_LOGIN, PATH_AFTER_CONTRACTOR_LOGIN, PATH_AFTER_SUPERADMIN_LOGIN, PATH_AFTER_TECHNICIAN_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    </>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <ContractorLogin />
        </GuestGuard>
      ),
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AdminLogin />
        </GuestGuard>
      ),
    },
    {
      path: 'admin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AdminLogin />
            </GuestGuard>
          ),
        },
        // Admin Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <AdminLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <AdminDashboard /> },
            { path: 'company', element: <AdminCompany /> },
            { path: 'contact', element: <AdminContact /> },
            { path: 'location', element: <AdminLocation /> },
            { path: 'credential', element: <AdminCredential /> },
            { path: 'assets', element: <AdminAssets /> },
            { path: 'customerfeedback', element: <AdminCustomerFeedBack /> },
            { path: 'customerapproval', element: <AdminCustomerApproval /> },
            { path: 'contractorapproval', element: <AdminContractorApproval /> },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/admin/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
          ],
        },
        // { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
      ],
    },

    // Customer Auth
    {
      path: 'customer',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <CustomerLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        // Customer Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <CustomerLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_CUSTOMER_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <CustomerDashboard /> },
            {
              path: 'ourcustomer',
              children: [
                { element: <Navigate to="/customer/ourcustomer/companyinfo" replace />, index: true },
                { path: 'companyinfo', element: <CustomerCompanyInfo /> },
                { path: 'usercontactinfo', element: <UserContactInfo /> },
                { path: 'locationinfo', element: <LocationInfo /> },
                { path: 'elevatorinfo', element: <CustomerElevator /> },
                { path: 'fireinfo', element: <CustomerFireDep /> },
                { path: 'keylogsinfo', element: <CustomerKeyLogs /> },
                // { path: 'firealarmasset', element: <CustomerFireAlarmAsset /> },
                // { path: 'wetsprinkler', element: <CustomerWetsprinkler /> },
                // { path: 'drysprinkler', element: <DrySprinkler /> },
                // { path: 'preactionsprinkler', element: <Preaction /> },
                // { path: 'delugesprinkler', element: <Deluge /> },
                // { path: 'standpipe', element: <Standpipe /> },
                // { path: 'firepump', element: <Firepumpsprinkler /> },
                // { path: 'FireHydrant', element: <FireHydrant /> },
                // { path: 'kitchenhood', element: <KitchenHoodSprinkler /> },
                // { path: 'drychemical', element: <DryChemical /> },
                // { path: 'cleanagent', element: <CleanAgent /> },
                // { path: 'companylocationquantity', element: <CompanyLocationQuantity /> },
                // { path: 'fireescape', element: <FireEscape /> },
                // { path: 'fireextinguisher', element: <FireExtinguisher /> },
                // { path: 'backflow', element: <Backflow /> },
                // { path: 'othersprinkler', element: <OtherSprinkler /> },

                { path: 'deficiencyentryform', element: <DeficiencyEntryForm /> },
                { path: 'Jobentryform', element: <JobEntryForm /> },
                { path: 'locationassetinventory', element: <LocationAssetInventory /> },
                { path: 'serviceentryform', element: <ServiceEntryForm /> },
              ]
            },
            { path: 'location', element: <CustomerLocation /> },
            {
              path: 'ourtask',
              children: [
                { element: <Navigate to="/customer/ourtask/saleslead" replace />, index: true },
                { path: 'saleslead', element: <CustomerSalesleads /> },
                { path: 'onboarding', element: <CustomerOnboardings /> },
                { path: 'reviewdataupdate', element: <CustomerReviewdataupdates /> },
                { path: 'reviewupload', element: <CustomerReviewuploads /> },
                { path: 'scheduleservice', element: <CustomerScheduleservices /> },

              ],
            },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/admindashboard/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
            {
              path: 'deficiency', element: <CuDeficiencyEntryForm />
            },
          ],
        },
      ],
    },

    // Contractor Auth
    {
      path: 'contractor',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <ContractorLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <ConRegister />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ConResetPassword /> },
        { path: 'new-password', element: <ConNewPassword /> },
        { path: 'verify', element: <ConVerifyCode /> },
        // Contractor Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <ContractorLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_CONTRACTOR_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <ContractorDashboard /> },
            {
              path: 'ourcontractor',
              children: [
                { element: <Navigate to="/contractor/ourcontractor/companyinfo" replace />, index: true },
                { path: 'companyinfo', element: <ContractorCompanyInfo /> },
                { path: 'usercontactinfo', element: <ContractorUserContactInfo /> },
                { path: 'addtechnician', element: <AddTechnician /> },
                { path: 'reviewtechnician/:id', element: <ReviewTechnician /> },
                { path: 'edittechnician/:id', element: <EditTechnician /> },
                { path: 'services', element: <ConServices /> },
                { path: 'technician', element: <ConTechnician /> },
                { path: 'conserviceentryform/:Serviceid', element: <ConServiceEntryForm /> },
                { path: 'job', element: <ContractorJob /> },
                { path: 'conjobentryform/:conid', element: <ConJobEntryForm /> },
                { path: 'mytask', element: <ContractorTask /> },
                { path: 'conjobtechentryform/:conid', element: <ConJobTechEntryForm /> },
                { path: 'mycustomer', element: <MyCustomer /> },

                // { path: 'companyinfo', element: <ContractorCompanyInfo /> },
              ],
            },
            { path: 'location', element: <ContractorLocation /> },
            // { path: 'company', element: <ConCompany /> },

            {
              path: 'ourcompany',
              children: [
                { element: <Navigate to="/contractor/ourcompany/companyinfo" replace />, index: true },
                { path: 'companyinfo', element: <CompanyInfo /> },
                // { path: 'salelead', element: <Salelead /> },
                // { path: 'onboarding', element: <Onboarding /> },
                // { path: 'scheduleservice', element: <Scheduleservice /> },
                // { path: 'reviewuploads', element: <Reviewuploads /> },
                // { path: 'account', element: <UserAccount /> },
              ],
            },

            {
              path: 'ourtask',
              children: [
                { element: <Navigate to="/contractor/panel/ourcontractor/mytask" replace />, index: true },
                { path: 'firealaram', element: <FireAlaram /> },
                { path: 'wetsprinkler', element: <WetSprinkler /> },
                { path: 'drysprinkler', element: <DrySprinkler /> },
                { path: 'preaction', element: <Preaction /> },
                { path: 'deluge', element: <Deluge /> },
                { path: 'standpipe', element: <StandPipe /> },
                { path: 'firepump', element: <Firepump /> },
                { path: 'FireHydrant', element: <FireHydrant /> },
                { path: 'kitchenhood', element: <KitchenHood /> },
                { path: 'drychemical', element: <DryChemical /> },
                { path: 'cleanagent', element: <CleanAgent /> },
                { path: 'fireescape', element: <FireEscape /> },
                { path: 'fireextinguisher', element: <FireExtinguisher /> },
                { path: 'backflow', element: <BackFlow /> },

              ],
            },
          ],
        },
      ],
    },

    // Technician Auth
    {
      path: 'technician',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <TechnicianLogin />
            </GuestGuard>
          ),
        },
        // Technician Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <TechnicianLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_TECHNICIAN_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <TecDashboard /> },
            { path: 'task', element: <TecTask /> },
            { path: 'technicianinfo', element: <TecInfo /> },
            { path: 'jobs', element: <TecJob /> },
            { path: 'jobscomingsoon', element: <TecJobcoming /> },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/admindashboard/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
          ],
        },
      ],
    },
    // SuperAdmin Auth
    {
      path: 'superadmin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <SuperAdminLogin />
            </GuestGuard>
          ),
        },
        // Super Admin Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <SuperAdminLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_SUPERADMIN_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <SuperDashboard /> },
            { path: 'company', element: <Company /> },
            { path: 'contact', element: <Contact /> },
            { path: 'location', element: <Location /> },
            { path: 'credential', element: <Credential /> },
            { path: 'assets', element: <Assets /> },
            { path: 'customerfeedback', element: <CustomerFeedBack /> },
            { path: 'customerapproval', element: <SACustomerApproval /> },
            { path: 'contractorapproval', element: <ContractorApproval /> },

            {
              path: 'user',
              children: [
                { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
          ],
        },
      ],
    },
    // SuperAdmin Routes


    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <Login />,
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

//  Authentication
const AdminLogin = Loadable(lazy(() => import('../pages/auth/AdminLogin')));
const CustomerLogin = Loadable(lazy(() => import('../pages/auth/CustomerLogin')));
const ContractorLogin = Loadable(lazy(() => import('../pages/auth/ContractorLogin')));
const TechnicianLogin = Loadable(lazy(() => import('../pages/auth/TechnicianLogin')));
const SuperAdminLogin = Loadable(lazy(() => import('../pages/auth/SuperAdminLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/CustomerRegister')));
const ConRegister = Loadable(lazy(() => import('../pages/auth/ContractorRegister')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/CustomerResetPassword')));
const ConResetPassword = Loadable(lazy(() => import('../pages/auth/ContractorResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/CustomerNewPassword')));
const ConNewPassword = Loadable(lazy(() => import('../pages/auth/ContractorNewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/CustomerVerifyCode')));
const ConVerifyCode = Loadable(lazy(() => import('../pages/auth/ContractorVerifyCode')));
// Admin
const AdminDashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const AdminCompany = Loadable(lazy(() => import('../pages/admin/Company')));
const AdminContact = Loadable(lazy(() => import('../pages/admin/Contact')));
const AdminLocation = Loadable(lazy(() => import('../pages/admin/Location')));
const AdminCredential = Loadable(lazy(() => import('../pages/admin/Credential')));
const AdminAssets = Loadable(lazy(() => import('../pages/admin/Assets')));
const AdminCustomerFeedBack = Loadable(lazy(() => import('../pages/admin/CustomerFeedBack')));
const AdminCustomerApproval = Loadable(lazy(() => import('../pages/admin/Customerapproval')));
const AdminContractorApproval = Loadable(lazy(() => import('../pages/admin/ContractorApproval')));
// Customer
const CustomerDashboard = Loadable(lazy(() => import('../pages/customer/Dashboard')));
const CustomerCompanyInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/CustomerCompanyInfo')));
const UserContactInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/UserContactInfo')));
const LocationInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationInfo')));
const DeficiencyEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/DeficiencyEntryForm')));
const JobEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/JobEntryForm')));
const LocationAssetInventory = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetInventory')));
const ServiceEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/ServiceEntryForm')));
const CustomerLocation = Loadable(lazy(() => import('../pages/customer/Location')));
const CustomerElevator = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/Elevator')));
const CustomerFireDep = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/FireDeparte')));
const CustomerKeyLogs = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/KeyLogs')));
const CustomerFireAlarmAsset = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireAlarmAsset')));
const CustomerWetsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Wetsprinkler')));
// const DrySprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DrySprinkler')));
// const Preaction = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Preaction')));
// const Deluge = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DelugeSprinkler')));
// const Standpipe = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/StandpipeSprinkler')));
// const Firepumpsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Firepumpsprinkler')));
// const FireHydrant = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireHydrantsprinkler')));
// const KitchenHoodSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/KitchenHoodSprinkler')));
// const DryChemical = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DryChemicalSprinkler')));
// const CleanAgent = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/CleanAgentSprinkler')));
// const FireEscape = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/EscapeSprinkler')));
// const FireExtinguisher = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireExtinguisherSprinkler')));
// const Backflow = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/BackflowSprinkler')));
const OtherSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/OtherSprinkler')));
const CompanyLocationQuantity = Loadable(lazy(() => import('../pages/customer/ourcompany/CompanyLocationQuantity')));
const CustomerSalesleads = Loadable(lazy(() => import('../pages/customer/Salesleads')));
const CustomerOnboardings = Loadable(lazy(() => import('../pages/customer/Onboardings')));
const CustomerReviewdataupdates = Loadable(lazy(() => import('../pages/customer/Reviewdataupdates')));
const CustomerReviewuploads = Loadable(lazy(() => import('../pages/customer/Reviewuploads')));
const CustomerScheduleservices = Loadable(lazy(() => import('../pages/customer/Scheduleservices')));
const CuDeficiencyEntryForm = Loadable(lazy(() => import('../sections/@Customer/CuDeficiencyEntryForm')));


// Contractor
const ContractorDashboard = Loadable(lazy(() => import('../pages/contractor/Dashboard')));
const ContractorCompanyInfo = Loadable(lazy(() => import('../pages/contractor/ourcompany/ContractorCompanyInfo')));
const ContractorUserContactInfo = Loadable(lazy(() => import('../pages/contractor/ourcompany/ContractorUserContactInfo')));
const ConTechnician = Loadable(lazy(() => import('../pages/contractor/ourcompany/ConTechnician')));
const AddTechnician = Loadable(lazy(() => import('../pages/contractor/ourcompany/AddTechnician')));
const ReviewTechnician = Loadable(lazy(() => import('../pages/contractor/ourcompany/ReviewTechnician')));
const EditTechnician = Loadable(lazy(() => import('../pages/contractor/ourcompany/EditTechnician')));
const ConServiceEntryForm = Loadable(lazy(() => import('../pages/contractor/ourcompany/ConServiceEntryForm')));
const ConJobEntryForm = Loadable(lazy(() => import('../pages/contractor/ourcompany/ConJobEntryForm')));
const ContractorTask = Loadable(lazy(() => import('../pages/contractor/ourcompany/MyTask')));
const ConJobTechEntryForm = Loadable(lazy(() => import('../pages/contractor/ourcompany/ConJobTechEntryForm')));
const ContractorLocation = Loadable(lazy(() => import('../pages/contractor/Location')));
const ContractorJob = Loadable(lazy(() => import('../pages/contractor/ourcompany/ContractorJob')));
const MyCustomer = Loadable(lazy(() => import('../pages/contractor/ourcompany/MyCustomer')))
const CompanyInfo = Loadable(lazy(() => import('../pages/contractor/ourcompany/CompanyInfo')));
const FireAlaram = Loadable(lazy(() => import('../pages/contractor/FireAlaram')));
const WetSprinkler = Loadable(lazy(() => import('../pages/contractor/WetSprinkler')));
const DrySprinkler = Loadable(lazy(() => import('../pages/contractor/DrySprinkler')));
const Preaction = Loadable(lazy(() => import('../pages/contractor/PreAction')));
const Deluge = Loadable(lazy(() => import('../pages/contractor/Deluge')));
const StandPipe = Loadable(lazy(() => import('../pages/contractor/StandPipe')));
const Firepump = Loadable(lazy(() => import('../pages/contractor/FirePump')));
const FireHydrant = Loadable(lazy(() => import('../pages/contractor/FireHydrant')));
const KitchenHood = Loadable(lazy(() => import('../pages/contractor/KitchenHood')));
const DryChemical = Loadable(lazy(() => import('../pages/contractor/DryChemical')));
const CleanAgent = Loadable(lazy(() => import('../pages/contractor/CleanAgent')));
const FireEscape = Loadable(lazy(() => import('../pages/contractor/Escape')));
const FireExtinguisher = Loadable(lazy(() => import('../pages/contractor/FireExtinguisher')));
const BackFlow = Loadable(lazy(() => import('../pages/contractor/BackFlow')));

const ConServices = Loadable(lazy(() => import('../pages/contractor/ConServices')));


// Technician
const TecDashboard = Loadable(lazy(() => import('../pages/technician/Dashboard')));
const TecTask = Loadable(lazy(() => import('../pages/technician/Techtask')));
const TecInfo = Loadable(lazy(() => import('../pages/technician/TechInfo')));
const TecJob = Loadable(lazy(() => import('../pages/technician/Jobschedule')));
const TecJobcoming = Loadable(lazy(() => import('../pages/technician/Jobcoming')));


// Super Admin
const SuperDashboard = Loadable(lazy(() => import('../pages/superadmin/Dashboard')));
const Company = Loadable(lazy(() => import('../pages/superadmin/Company')));
const Contact = Loadable(lazy(() => import('../pages/superadmin/Contact')));
const Location = Loadable(lazy(() => import('../pages/superadmin/Location')));
const Credential = Loadable(lazy(() => import('../pages/superadmin/Credential')));
const Assets = Loadable(lazy(() => import('../pages/superadmin/Assets')));
const CustomerFeedBack = Loadable(lazy(() => import('../pages/superadmin/CustomerFeedBack')));
const UserProfile = Loadable(lazy(() => import('../pages/superadmin/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/superadmin/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/superadmin/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/superadmin/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/superadmin/UserCreate')));
const SACustomerApproval = Loadable(lazy(() => import('../pages/superadmin/CustomerApproval')));
const ContractorApproval = Loadable(lazy(() => import('../pages/superadmin/ContractorApproval')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
