// routes
import { PATH_CONTRACTOR } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_kanban'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_banking'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  location: getIcon('ic_location'),
  task: getIcon('ic_ourtask'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_CONTRACTOR.general.dashboard, icon: ICONS.dashboard },

      {
        title: 'Our Contract',
        path: PATH_CONTRACTOR.ourcontractor.root,
        icon: ICONS.user,
        children: [
          { title: 'Company Info', path: PATH_CONTRACTOR.ourcontractor.companyinfo },
          { title: 'User Contact Info', path: PATH_CONTRACTOR.ourcontractor.usercontactinfo },
          { title: 'Services', path: PATH_CONTRACTOR.ourcontractor.conservices, },
          { title: 'mycustomer', path: PATH_CONTRACTOR.ourcontractor.mycustomer, },
          { title: 'Technician', path: PATH_CONTRACTOR.ourcontractor.contechnician, },
          { title: 'Job', path: PATH_CONTRACTOR.ourcontractor.contractorjob, },
          {
            title: 'Our Task',
            path: PATH_CONTRACTOR.ourtask.root,
            icon: ICONS.task,}
        ],
      },
      // {
      //   title: 'Our Task',
      //   path: PATH_CONTRACTOR.ourtask.root,
      //   icon: ICONS.task,
      //   children: [
      //     { title: 'Fire Alaram', path: PATH_CONTRACTOR.ourtask.firealaram },
      //     { title: 'Wet Sprinkler', path: PATH_CONTRACTOR.ourtask.wetsprinkler },
      //     { title: 'Dry Sprinkler', path: PATH_CONTRACTOR.ourtask.drysprinkler },
      //     { title: 'Preaction', path: PATH_CONTRACTOR.ourtask.preaction },
      //     { title: 'Deluge', path: PATH_CONTRACTOR.ourtask.deluge },
      //     { title: 'Standpipe', path: PATH_CONTRACTOR.ourtask.standpipe },
      //     { title: 'Fire Pump', path: PATH_CONTRACTOR.ourtask.firepump },
      //     { title: 'Fire Hydrant', path: PATH_CONTRACTOR.ourtask.firehydrant },
      //     { title: 'Kitchen Hood', path: PATH_CONTRACTOR.ourtask.kitchenhood },
      //     { title: 'Dry Chemical', path: PATH_CONTRACTOR.ourtask.drychemical },
      //     { title: 'Clean Agent', path: PATH_CONTRACTOR.ourtask.cleanagent },
      //     { title: 'fire Escape', path: PATH_CONTRACTOR.ourtask.fireescape },
      //     { title: 'Fire Extinguisher', path: PATH_CONTRACTOR.ourtask.fireextinguisher },
      //     { title: 'Backflow', path: PATH_CONTRACTOR.ourtask.backflow },
          
      //   ],
      // },

      

    ],
  },
];

export default navConfig;
